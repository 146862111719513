import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonsFloating from 'components/ButtonsFloating'
import Copy from 'components/Copy'
import Input from 'components/Input'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'

export default function OnboardingEnterpriseIneligibleFacebook() {
  const globalContext = useGlobalContext()
  const [inputValue, setInputValue] = React.useState(globalContext.user?.healthCareProvider || '')
  const [isIndeterminate, setIsIndeterminate] = React.useState<boolean>(false)

  React.useEffect(() => {
    async function checkStatus() {
      const eventsResponse = await userApiRequest.checkIfReefOrcaEligibilityIsPending()
      // User has a scheduled event to recheck elig, so we aren't sure about their status yet
      if (eventsResponse.code === 200 && eventsResponse.data) {
        globalContext.analytics?.markPageVisited('Indeterminate eligibility from special')
        setIsIndeterminate(true)
      }
    }
    checkStatus()
  }, [globalContext.analytics])

  async function handleApiRequest(healthCareProvider: string, successPath: string) {
    try {
      const response = await userApiRequest.updateUser({
        healthCareProvider,
      })
      navigate(successPath)

      await globalContext.update({ user: response.data })
      await globalContext.updateUser()
    } catch {
      notifications.notifyError()
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(event.target.value)
  }

  function startProgram() {
    globalContext.analytics?.trackEvent(events.FACEBOOK_INELIGIBLE_USERS_TO_PROGRAM, {
      isIndeterminate: isIndeterminate ? 'true' : 'false',
    })
    handleApiRequest(inputValue, paths.ONBOARDING_PROGRAM_BUILD)
  }

  function checkAgain() {
    globalContext.analytics?.trackEvent(events.FACEBOOK_ENTERPRISE_ELIGIBILITY_RECHECK)
    handleApiRequest(inputValue, paths.ENTERPRISE_ELIGIBLE)
  }

  return (
    <OnboardingView
      small={
        isIndeterminate ? (
          `We’re still checking`
        ) : (
          <>
            <Text size="large">We couldn’t verify your eligibility</Text>
          </>
        )
      }
      title={
        isIndeterminate ? (
          <>It’s taking longer than usual to verify your eligibility</>
        ) : (
          <>
            But, we’d like to give you{' '}
            <Text casing="upper" color="purple">
              free
            </Text>{' '}
            unlimited access to Bold for 14 days
          </>
        )
      }>
      <Row size="xxsmall">
        <Copy>
          {isIndeterminate && (
            <Text element="p">
              Don’t worry, you can still start moving with Bold{' '}
              <Text color="purple" weight="bold">
                at no cost
              </Text>
              . We will keep working to verify your eligibility in the meantime.
            </Text>
          )}
          <Text element="h4">
            Enter the name of your health plan: <Text weight="normal">(optional)</Text>
          </Text>
          {!isIndeterminate && (
            <Text element="p">
              We’ll reach out if your health plan partners with Bold in the future.
            </Text>
          )}
        </Copy>
      </Row>
      <Row size="xsmall">
        <Input
          maxLength={50}
          placeholder="Type the name of your health plan here…"
          value={inputValue}
          onChange={handleInputChange}
        />
      </Row>
      <ButtonsFloating>
        <Buttons axis="y">
          <Button full size="large" onClick={startProgram}>
            Start my program
          </Button>
          {!isIndeterminate && (
            <Button full size="large" color="white" onClick={checkAgain}>
              Try checking again
            </Button>
          )}
        </Buttons>
      </ButtonsFloating>
    </OnboardingView>
  )
}
