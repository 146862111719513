import _ from 'lodash'
import React from 'react'
import Announcement from 'components/Announcement'
import Container from 'components/Container'
import Rows from 'components/Rows'
import View from 'components/View'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import AppDownloadModal from 'features/app/components/AppDownloadModal'
import Challenge from 'features/user/components/Challenge'
import * as challenge from 'libs/challenge'
import { useUserChallenges } from 'libs/challenge-query'
import * as cookie from 'libs/cookie'
import * as lessonsQuery from 'libs/lessons-query'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Dashboard.module.scss'
import DashboardActivity from './components/DashboardActivity'
import DashboardLessonsFavorite from './components/DashboardLessonsFavorite'
import DashboardLessonsRecommended from './components/DashboardLessonsRecommended'
import DashboardLiveLessons from './components/DashboardLiveLessons'
import DashboardToday from './components/DashboardToday'

export default function Dashboard() {
  const globalContext = useGlobalContext()
  const [data, setData] = React.useState<null | any>(null)
  const { data: lessonsFavorite, isLoading } = lessonsQuery.useUserLessonsFavorite()
  const lessonsFavoriteData = lessonsFavorite?.data
  const { data: challenges } = useUserChallenges(
    {
      isChallengeActive: true,
      status: 'active',
      type: 'daily',
    },
    {
      revalidateOnFocus: true,
    }
  )
  const challengesData = challenges?.data
  const [showBanner, setShowBanner] = React.useState<boolean>(
    !cookie.getCookie(storage.TODAYS_PLAN_4_2)
  )

  React.useEffect(() => {
    if (_.isNil(challengesData)) return
    const learnChallenge = challengesData.find(
      (item: any) =>
        item.tag === challenge.CHALLENGE_TAG.LEARN ||
        item.challenge?.tag === challenge.CHALLENGE_TAG.LEARN
    )
    const moveChallenge = challengesData.find(
      (item: any) =>
        item.tag === challenge.CHALLENGE_TAG.MOVE ||
        item.challenge?.tag === challenge.CHALLENGE_TAG.MOVE
    )
    const now = new Date().toISOString().split('T')[0]
    const moveEndDate = moveChallenge?.endDate
    const learnEndDate = learnChallenge?.endDate
    if ((moveEndDate && moveEndDate < now) || (learnEndDate && learnEndDate < now)) {
      const show = !cookie.getCookie(storage.TODAYS_PLAN_4_2)
      setShowBanner(show)
      globalContext?.analytics?.trackEvent('TP Banner 4/2', {
        showBanner: show ? 'true' : 'false',
      })
    } else {
      setShowBanner(false)
    }
  }, [challengesData, globalContext?.analytics])

  React.useEffect(() => {
    if (!globalContext.user) return

    async function getData() {
      const response = await userApiRequest.getUserDashboard()

      if (response.statusCode !== 200) return
      setData(response.data)
    }
    getData()
  }, [globalContext.user])

  function handleClick() {
    cookie.createCookie(storage.TODAYS_PLAN_4_2, storage.ANNOUNCEMENT_DISMISSED)
    setShowBanner(false)
  }

  const hasLessonsFavorite = !isLoading && lessonsFavoriteData && !_.isEmpty(lessonsFavoriteData)

  return (
    <div className="Dashboard" data-test-id="dashboard">
      <AppDownloadModal />
      {showBanner && (
        <Announcement title="Update on Today's plan (4/2/25)" section onCloseClick={handleClick}>
          Sorry, you may see the same recommendation from yesterday. We're working to refresh it.
          Please check back soon!
        </Announcement>
      )}
      <View color="grey" className={styles.view}>
        <Container flush size="xxlarge">
          <Rows size="large">
            <DashboardToday />
            <DashboardActivity data={data?.activity} />
            <Challenge from={paths.HOME_AUTHED} />
            <DashboardLiveLessons data={data?.liveLessons} />
            <DashboardLessonsFavorite data={lessonsFavoriteData} isLoading={isLoading} />
            {!hasLessonsFavorite && <DashboardLessonsRecommended />}
          </Rows>
        </Container>
      </View>
    </div>
  )
}
